<template>
  <div class="shopping-cart-counter">
    <div
      class="action"
      :class="{ disabled: !isMinusable }"
      @click="handleMinus"
    >
      &ndash;
    </div>

    <div class="text-orange">
      {{ item.qnt }}
    </div>

    <div class="action" :class="{ disabled: !isPlusable }" @click="handlePlus">
      +
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isMinusable() {
      return this.item.qnt > 1;
    },

    isPlusable() {
      return this.item.qnt < +this.item.item.quantity;
    },
  },

  methods: {
    handlePlus() {
      if (this.isPlusable) {
        this.$store.dispatch('cartIncreaseByOne', this.item.item.id);
      }
    },
    handleMinus() {
      if (this.isMinusable) {
        this.$store.dispatch('cartReduceByOne', this.item.item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shopping-cart-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100px;
  height: 44px;
  background: #fff;
  border: 1px solid #999999;
  border-radius: 10px;
  font-size: 20px;

  .action {
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    color: #2b2b2b;

    &.disabled {
      color: rgba(43, 43, 43, 0.5);
      cursor: auto;
    }
  }
}
</style>
