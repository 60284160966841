<template>
  <div class="cart-item-widget">
    <div class="counter">
      <div class="shopping-cart-counter">
        <div
          class="action"
          :class="{ disabled: !isMinusable }"
          @click="handleMinus"
        >
          &ndash;
        </div>

        <div class="text-orange">
          {{ counter }}
        </div>

        <div
          class="action"
          :class="{ disabled: !isPlusable }"
          @click="handlePlus"
        >
          +
        </div>
      </div>
    </div>
    <div class="add-button">
      <Button
        @click="addToCart()"
        :disabled="!isAvailable || counter === 0"
        :orange="true"
        :loading="$store.getters.loading"
      >
        <PlusIcon width="22px" height="22px" />
        {{ $t('buttons.addToCart') }}
      </Button>
    </div>
    <div class="icons-wrapper">
      <div
        class="bell-wrapper"
        :class="{ active: notificationAlarm }"
        @click="setNotificationAlarm()"
      >
        <BellIcon :color="!notificationAlarm ? '#BFBFBF' : '#E58413'" />
      </div>
      <div class="info-wrapper"></div>
    </div>
  </div>
</template>

<script>
import Counter from '@/components/ShoppingCart/ShoppingCartCounter';
import Button from '@/components/FormControl/ButtonElement';
import PlusIcon from '@/components/Svgs/PlusIcon';
import BellIcon from '../Svgs/BellIcon';

export default {
  components: {
    BellIcon,
    Button,
    PlusIcon,
    Counter,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cartItem: null,
      notificationAlarm: false,
      counter: 1,
      loading: false,
    };
  },
  computed: {
    isAvailable() {
      return !!+this.product.quantity;
    },

    isMinusable() {
      return this.isAvailable && this.counter > 1;
    },

    isPlusable() {
      return (
        this.isAvailable &&
        this.counter < +this.product.quantity - this.cartItem.qnt
      );
    },
  },
  created() {
    this.cartItem = this.$store.getters.cartItems.find(
      i => i.item.id == this.product.id
    );

    if (!this.cartItem) {
      this.cartItem = { qnt: 0 };
    }

    if (!this.isPlusable) {
      this.counter = 0;
    }
  },
  methods: {
    setNotificationAlarm() {
      this.notificationAlarm = !this.notificationAlarm;
    },
    addToCart() {
      this.$store
        .dispatch('addToCart', [
          {
            id: this.product.id,
            qnt: this.counter,
          },
        ])
        .then(() => {
          this.$router.push({ name: 'Cart' });
        });
    },
    handlePlus() {
      if (this.isPlusable) {
        ++this.counter;
      }
    },
    handleMinus() {
      if (this.isMinusable) {
        --this.counter;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-item-widget {
  display: flex;
}
.add-button {
  margin: 0 38px;
}
.icons-wrapper {
  align-items: center;
  display: flex;
  .bell-wrapper {
    align-items: center;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    margin-right: 15px;
    width: 44px;
    &.active {
      border-color: #e58413;
    }
  }
  .info-wrapper {
    border: 1px solid #bfbfbf;
    border-radius: 50%;
    color: #bfbfbf;
    font-size: 8px;
    height: 14px;
    position: relative;
    width: 14px;
    &:after {
      content: 'i';
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.shopping-cart-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100px;
  height: 44px;
  background: #fff;
  border: 1px solid #999999;
  border-radius: 10px;
  font-size: 20px;

  .action {
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    color: #2b2b2b;

    &.disabled {
      color: rgba(43, 43, 43, 0.5);
      cursor: auto;
    }
  }
}
</style>
