<template>
  <div class="products-product" v-if="product">
    <div class="row" v-if="!+product.quantity">
      <div class="col-12">
        <NotAvailable />
      </div>
    </div>
    <div class="main row">
      <div class="gallery col-12 col-md-6">
        <Gallery :images-data="parseImages()" :view-only="true" />
      </div>
      <div class="details col-12 col-md-6">
        <div class="title mb-0">
          {{ product.name }}
        </div>
        <div class="description">
          {{ product.description }}
        </div>
        <div class="price">{{ product.price | EUR }}</div>
        <div class="cart">
          <CartWidget :product="product" @addToCart="prepareToCart" />
          <ResponseAlert
            v-if="$store.getters.errorResponse.action == 'addToCart'"
            :response="$store.getters.errorResponse.data"
            class="mt-3"
          ></ResponseAlert>
        </div>
        <div class="delivery">
          <Delivery :product="product" />
        </div>
        <div class="additional-info mt-3">
          <div class="title">
            {{ $t('enduser.products.additionalInformation') }}
          </div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          ullamca, pellentesque tempor penatibus volutpat, nibh imperdiet.
          Lectus habitasse cursus lorem turpis tempor nunc. Eu enim dolor morbi
          massa, posuere egestas morbi. Nulla id in amet ultrices vitae quis
          est. Id lectus ac id amet augue.
        </div>
      </div>
    </div>

    <div class="mt-5">
      <ButtonElement
        orange
        :anchor="{
          name: 'ProductsCompany',
          params: { id: $route.params.company },
        }"
        :text="$t('buttons.toCompany')"
      />
    </div>
  </div>
</template>

<script>
import map from 'lodash/map';
import Gallery from '@/components/Products/ProductsGallery';
import NotAvailable from '@/components/Products/ProductsCardNotavailable';
import Delivery from '@/components/Products/ProductsCardDelivery';
import CartWidget from '@/components/ShoppingCart/ShoppingCartItemWidget';
import checkIsAndParseJson from '../../utils/checkIsAndParseJson';

export default {
  components: {
    Gallery,
    NotAvailable,
    Delivery,
    CartWidget,
  },
  data() {
    return {
      product: null,
    };
  },
  methods: {
    prepareToCart(event) {
      const cart = { ...this.$store.getters.cart };
      if (!cart[this.product.id]) {
        cart[this.product.id] = {
          id: this.product.id,
          quantity: 0,
        };
      }
      const data = map(cart, item => {
        const quantity =
          this.product.id === item.id ? item.quantity + event : item.quantity;
        return {
          id: item.id,
          qnt: quantity,
        };
      });
      this.$store.dispatch('apiAddToCart', data);
    },
    parseImages() {
      return checkIsAndParseJson(this.product.images);
    },
  },
  created() {
    this.$store
      .dispatch('fetchProduct', {
        companyId: this.$route.params.company,
        productId: this.$route.params.product,
      })
      .then(resp => {
        this.product = resp.data.data.product[0];
      })
      .catch(() => {
        alert(404);
      });
  },
};
</script>

<style lang="scss" scoped>
.products-product {
  color: #484848;
  font-weight: 400;
}

.gallery {
}
.details {
  .price,
  .title {
    font-weight: bold;
    font-size: 24px;
    color: #e58413;
  }
  .price {
    margin: 20px 0;
  }
  .additional-info {
    .title {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
}
.cart {
  margin-bottom: 20px;
}
.main {
  margin-top: 25px;
}
</style>
