<template>
  <div class="products-card-notavailable">
    <div class="content">
      <div class="message">
        {{ $t('enduser.products.productIsNotAvailable') }}
      </div>
      <div class="details">
        {{ $t('enduser.products.sorryTheProductIsNot') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsCardNotavailable',
};
</script>

<style lang="scss" scoped>
.products-card-notavailable {
  width: 100%;
  height: 103px;
  background: rgba(229, 132, 19, 0.15);
  display: flex;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message {
  font-weight: bold;
  font-size: 20px;
  color: #e58413;
}
.details {
  width: 50%;
  font-weight: normal;
  font-size: 16px;
  color: #999999;
  text-align: center;
}
</style>
