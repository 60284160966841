<template>
  <div class="products-card-delivery">
    <div class="message">
      <span>
        <TruckIcon color="#e58413" />
      </span>
      <span v-if="delivery.delivery.enabled">
        {{
          $t('enduser.products.deliveryAvailableInNDays', {
            n: delivery.delivery.delivery_days,
          })
        }}
      </span>
      <span v-else>{{ $t('enduser.products.deliveryIsNotAvailableNow') }}</span>
    </div>
  </div>
</template>

<script>
import TruckIcon from '@/components/Svgs/TruckIcon';

export default {
  components: {
    TruckIcon,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      delivery: null,
    };
  },

  computed: {},

  created() {
    this.delivery = JSON.parse(this.product.delivery);
  },
};
</script>

<style lang="scss" scoped>
.products-card-delivery {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #f6f6f6;
  border-radius: 10px;
}

.message,
.message span:last-child {
  padding-left: 24px;
}
.message {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #484848;
}
</style>
